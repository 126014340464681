import React, { useState } from "react";
import { Grid, Typography,TextField,Button} from "@mui/material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { FormControl, MenuItem, Select } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {TimeSchedule} from '../components/timeSchedule';
export const EditScheduleSettings = ({scheduleEdit}) => {

    const navigate =  useNavigate();

    const [time, setTime] = useState('15 mins');  
    const [timeData,setTimeData] = useState(false);
    const [editSchedule,setEditSchedule] = useState(true);
//   const [customDuration, setCustomDuration] = useState('');
//   const [customUnit, setCustomUnit] = useState('mins');

  const handleChange = (event) => {
    setTime(event.target.value);
  };
  const renderValue = (selected) => {
    if (selected === '1') {
      return <Typography>Calendar days</Typography>;
    }
    if (selected === '2') {
      return <Typography>Week days</Typography>;
    }
    return <Typography>Select days</Typography>;
  };

  const timeScreen = () => {
    setTimeData(!timeData);
    setEditSchedule(!editSchedule);
  };

 
    return (
        <>
           {editSchedule&&<Grid conatiner justifyContent={'space-between'} >
                <Grid item p={4} className="createEventSidenavHeader">
                    <Grid container >
                        <Grid container item sx={{cursor:'pointer'}} onClick={scheduleEdit}>
                            <KeyboardArrowLeftIcon/>
                            <Typography >Cancel</Typography>
                        </Grid>
                    </Grid>
                    <Grid item component={'h1'} className='newEventType'>Scheduling settings</Grid>
                </Grid>
                <Grid item className="createEventSidenavHeader" >
                    <Grid>
                        <Typography className="dateLabel">Date range</Typography>
                        <Typography pt={2}>Invitees can schedule...</Typography>
                    </Grid>
                    <Grid container alignContent={'center'} gap={2}>
                        <Grid item md={2}>
                            <FormControl fullWidth>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    size="medium"
                                    className="eventTextField"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={6}>
                            <FormControl fullWidth>
                            <Select
                                labelId="time-select-label"
                                id="time-select"
                                value={time}
                                onChange={handleChange}
                                IconComponent={ExpandMoreIcon}
                                sx={{ borderRadius: '10px' }}
                                displayEmpty
                                renderValue={renderValue}
                                className="eventSelectField"
                                >
                                <MenuItem value="1">
                                    <Grid>
                                        <Grid>Calendar days</Grid>
                                        <Grid className="CalendarInfoText">
                                        Counts every day on the calendar including days you're unavailable
                                        </Grid>
                                    </Grid>
                                </MenuItem>
                                <MenuItem value="2">
                                    <Grid>
                                        <Grid>Week days</Grid>
                                        <Grid className="CalendarInfoText">
                                        Excludes weekends and only counts Mon-Fri
                                        </Grid>
                                    </Grid>
                                </MenuItem>
                            </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={2} mt={1}>
                            into the future
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className="createEventSidenavHeader" onClick={timeScreen} sx={{cursor:'pointer'}}>
                    <Grid container alignItems={'center'} justifyContent={'space-between'}>
                        <Grid item md={2} sx={{textAlign:'center'}}>
                            <Button>
                                <Grid container alignItems="center" className="profileLetter">
                                    <Grid item component="span">U</Grid>
                                </Grid>
                            </Button>
                        </Grid>
                        <Grid item md={7}>
                            <Grid>
                                <Typography pt={2} className="timeText">Profile Name (You)</Typography>
                                <Typography pt={2} className="timeText1">Weekdays, 9 am - 5 pm</Typography>
                            </Grid>
                        </Grid>
                        <Grid item md={2} container alignItems={'center'}>
                            <Grid >
                                <KeyboardArrowRightIcon/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item p={4}>
                    <Grid container justifyContent={'end'} alignItems={'center'}>
                        <Typography sx={{cursor:'pointer'}} onClick={() => navigate('/app/events')}>Cancel</Typography>
                        <Button className="continueBtn">Save and close</Button>
                    </Grid>
                </Grid>
            </Grid>}
            {timeData&&<Grid md={12} className="createEventSidenav editEventDetails">
                    <Grid>
                        <TimeSchedule timeEdit ={timeScreen} />
                    </Grid>
            </Grid>}
        </>
    )
};