import React from 'react';
import Lottie from 'lottie-react';
import LottieFile from '../assets/lottie.json';
import { Backdrop } from '@mui/material'; // If you're using MUI for the backdrop

export const BackdropWithLottie = ({ open, onClose }) => {
  return (
    <Backdrop
      open={open}
      onClick={onClose}
      sx={{
        color: '#fff',
        zIndex: (theme) => theme.zIndex.drawer + 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
      }}
    >
      <Lottie
        animationData={LottieFile}
        loop={true}
        autoplay={true}
        style={{ width: 800, height: 800 }}
      />
    </Backdrop>
  );
};

