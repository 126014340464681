import { AxiosClient, AxiosClientGoogle } from "../axios/client";

export const AuthServices = {

    fetchAccessToken: async (code) => {
        try {
            const response = await AxiosClient.post('getAccessToken', { code });
            return response.data;
        } catch (error) {
            console.error(error);
        }
    },

    fetchUserDetatils: async (accessToken) => {
        try {
            const response = await AxiosClientGoogle.post('getUserData', { token: accessToken });
            return response.data;
        } catch (error) {
            console.error(error);
        }
    },

    userSignIn: async (data) => {
        try {
            const response = await AxiosClient.post('check-email', data);
           
            return response.data;
            
        } catch (error) {
            console.error(error);
        }
    }
}