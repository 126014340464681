import { AxiosClient, AxiosClientGoogle } from "../axios/client";

export const ManagementServices = {
  eventAdd: async (data) => {
    try {
      const response = await AxiosClient.post("events", data);
      if (response.data) {
        return response.data;
      } else {
        return response;
      }
    } catch (error) {
      console.error(error);
    }
  },
  AllEvents: async (data) => {
    try {
      const response = await AxiosClient.post("AllEvents", data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  DeleteEvent: async (data) => {
    try {
      const response = await AxiosClient.post("deleteEvent", data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  gettingLink: async (data) => {
    try {
      const response = await AxiosClient.put("myLinkName", data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  LinkDetails: async (myLinkName) => {
    try {
      const response = await AxiosClient.get(`${myLinkName}`);
      return response;
    } catch (error) {
      console.error(error);
    }
  },
  getEventSlotDetails: async (myLinkName, eventId) => {
    try {
      const response = await AxiosClient.get(`${myLinkName}/${eventId}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  bookEventSlot: async (data) => {
    try {
      const response = await AxiosClientGoogle.post("schedule", data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  ScheduledEvents: async (data) => {
    try {
      const response = await AxiosClient.post("getAllEventsWithTense", data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  updateEventDetails: async (data) => {
    try {
      const response = await AxiosClient.put("updateEvent", data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  getProfile: async (data) => {
    try {
      const response = await AxiosClient.post("userProfile", data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  updateProfile: async (data) => {
    try {
      const response = await AxiosClient.put(`profile`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
};
