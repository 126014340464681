import React from 'react';
import './App.css';
import './styles/style.css';
import { BrowserRouter } from 'react-router-dom';
import MainRouter from './routes/router';
import { AuthProvider } from './components/authContext';

function App() {
  return (
    <div className="App">
      
      <AuthProvider>
        <BrowserRouter>
          <MainRouter />
        </BrowserRouter>
      </AuthProvider>
      
      
      
    </div>
  );
}

export default App;
