import { Container, Grid } from "@mui/material";
import React from "react";
import { Header } from "../components/layout_components/header";
import Footer from "../components/Footer";

const Privacypolicy = () => {
  return (
    <>
      <Header />
      <Container>
        <Grid container direction="column" spacing={1} p={2}>
          <Grid item component="h3" className="privacymainheading">
            Privacy Policy
          </Grid>
          <Grid item component="h4" className="privacyheading">
            Last Updated: 4th September 2024
          </Grid>
          <Grid item mt={1} component="h4" className="privacyheading">
            1. Introduction
          </Grid>
          <Grid item component="p" className="privacytext">
            Welcome to Krifly. We value your privacy and are committed to
            protecting your personal information. This Privacy Policy outlines
            how we collect, use, disclose, and safeguard your information when
            you visit our website and use our services, including our
            integration with Google Calendar APIs.
          </Grid>
          <Grid item component="h4" mt={1} className="privacyheading">
            2. Information We Collect
          </Grid>
          <Grid item component="h4" mt={1} className="privacyheading">
            2.1 Personal Information
          </Grid>
          <Grid item component="p" className="privacytext">
            When you use our website and services, we may collect personal
            information that you provide to us, including but not limited to:
          </Grid>
          <Grid
            item
            component="ul"
            sx={{
              padding: 0,
              margin: 0,
              "& > li": {
                listStyleType: "disc", // This sets the bullet (dot) style
                marginBottom: (theme) => theme.spacing(1),
                marginLeft: 4, // Optional: Indent the bullet points
              },
            }}
          >
            <Grid item component="li" className="privacytext">
              Your name
            </Grid>
            <Grid item component="li" className="privacytext">
              Email address
            </Grid>
            <Grid item component="li" className="privacytext">
              Contact information
            </Grid>
            <Grid item component="li" className="privacytext">
              Calendar events details
            </Grid>
          </Grid>
          <Grid item component="h4" mt={1} className="privacyheading">
            2.2 Usage Data
          </Grid>
          <Grid item component="p" className="privacytext">
            We collect information about your interactions with our website and
            services, such as:
          </Grid>
          <Grid
            item
            component="ul"
            sx={{
              padding: 0,
              margin: 0,
              "& > li": {
                listStyleType: "disc", // This sets the bullet (dot) style
                marginBottom: (theme) => theme.spacing(1),
                marginLeft: 4, // Optional: Indent the bullet points
              },
            }}
          >
            <Grid item component="li" className="privacytext">
              IP address
            </Grid>
            <Grid item component="li" className="privacytext">
              Browser type and version
            </Grid>
            <Grid item component="li" className="privacytext">
              Pages you visit
            </Grid>
            <Grid item component="li" className="privacytext">
              Time and date of your visit
            </Grid>
            <Grid item component="li" className="privacytext">
              Referring website
            </Grid>
          </Grid>
          <Grid item component="h4" mt={1} className="privacyheading">
            2.3 Google Calendar Data
          </Grid>
          <Grid item component="p" className="privacytext">
            When you interact with Google Calendar through our website, we may
            access the following data:
          </Grid>
          <Grid
            item
            component="ul"
            sx={{
              padding: 0,
              margin: 0,
              "& > li": {
                listStyleType: "disc", // This sets the bullet (dot) style
                marginBottom: (theme) => theme.spacing(1),
                marginLeft: 4, // Optional: Indent the bullet points
              },
            }}
          >
            <Grid item component="li" className="privacytext">
              Calendar events you create
            </Grid>
            <Grid item component="li" className="privacytext">
              Events you view
            </Grid>
            <Grid item component="li" className="privacytext">
              Event sharing details
            </Grid>
          </Grid>
          <Grid item component="h4" mt={1} className="privacyheading">
            3. How We Use Your Information
          </Grid>
          <Grid item component="p" className="privacytext">
            We use the collected information for the following purposes:
          </Grid>
          <Grid
            item
            component="ul"
            sx={{
              padding: 0,
              margin: 0,
              "& > li": {
                listStyleType: "disc", // This sets the bullet (dot) style
                marginBottom: (theme) => theme.spacing(1),
                marginLeft: 4, // Optional: Indent the bullet points
              },
            }}
          >
            <Grid item component="li" className="privacytext">
              To provide and maintain our website and services
            </Grid>
            <Grid item component="li" className="privacytext">
              To create, view, and share calendar events
            </Grid>
            <Grid item component="li" className="privacytext">
              To respond to your inquiries and provide customer support
            </Grid>
            <Grid item component="li" className="privacytext">
              To improve our website and services
            </Grid>
            <Grid item component="li" className="privacytext">
              To comply with legal obligations
            </Grid>
          </Grid>
          <Grid item component="h4" mt={1} className="privacyheading">
            4. How We Share Your Information
          </Grid>
          <Grid item component="p" className="privacytext">
            We do not sell, trade, or otherwise transfer your personal
            information to outside parties, except in the following cases:
          </Grid>
          <Grid
            item
            component="ul"
            sx={{
              padding: 0,
              margin: 0,
              "& > li": {
                listStyleType: "disc", // This sets the bullet (dot) style
                marginBottom: (theme) => theme.spacing(1),
                marginLeft: 4, // Optional: Indent the bullet points
              },
            }}
          >
            <Grid item component="li" className="privacytext">
              With Google, as part of the Google Calendar API integration
            </Grid>
            <Grid item component="li" className="privacytext">
              When required by law or to protect our rights and safety
            </Grid>
            <Grid item component="li" className="privacytext">
              With service providers who assist us in operating our website and
              services (e.g., hosting providers) under strict confidentiality
              agreements.
            </Grid>
          </Grid>
          <Grid item component="h4" mt={1} className="privacyheading">
            5. Data Security
          </Grid>
          <Grid item component="p" className="privacytext">
            We implement reasonable security measures to protect your personal
            information from unauthorized access, disclosure, alteration, or
            destruction. This includes the use of SSL, encryption, and ISO 27000
            compliance. However, no method of transmission over the internet or
            electronic storage is completely secure, and we cannot guarantee
            absolute security.
          </Grid>
          <Grid item component="h4" mt={1} className="privacyheading">
            6. Data Retention and Deletion
          </Grid>
          <Grid item component="p" className="privacytext">
            We retain personal data, including Google Calendar data, for up to
            10 days unless a longer retention period is required or permitted by
            law. After this period, the data is securely deleted. You have the
            right to request the deletion of your information at any time by
            contacting us at krifly@krify.com.
          </Grid>
          <Grid item component="h4" mt={1} className="privacyheading">
            7. Your Rights
          </Grid>
          <Grid item component="p" className="privacytext">
            Depending on your location, you may have the following rights
            regarding your personal information:
          </Grid>
          <Grid
            item
            component="ul"
            sx={{
              padding: 0,
              margin: 0,
              "& > li": {
                listStyleType: "disc", // This sets the bullet (dot) style
                marginBottom: (theme) => theme.spacing(1),
                marginLeft: 4, // Optional: Indent the bullet points
              },
            }}
          >
            <Grid item component="li" className="privacytext">
              The right to access and update your information
            </Grid>
            <Grid item component="li" className="privacytext">
              The right to request deletion of your information
            </Grid>
            <Grid item component="li" className="privacytext">
              The right to withdraw consent for data processing
            </Grid>
          </Grid>
          {/* contact info */}
          <Grid item component="p" className="privacytext">
            To exercise these rights, please contact us at krifly@krify.com. We
            will respond to your request within 30 days.
          </Grid>
          <Grid item component="h4" mt={1} className="privacyheading">
            8. Third-Party Links
          </Grid>
          <Grid item component="p" className="privacytext">
            Our website may contain links to third-party websites. We are not
            responsible for the privacy practices or content of these third
            parties. We encourage you to review their privacy policies before
            providing any personal information.
          </Grid>
          <Grid item component="h4" mt={1} className="privacyheading">
            9. Changes to This Privacy Policy
          </Grid>
          <Grid item component="p" className="privacytext">
            We may update this Privacy Policy from time to time. We will notify
            you of any significant changes by email. The updated policy will be
            effective immediately upon posting on this page.
          </Grid>
          <Grid item component="h4" mt={1} className="privacyheading">
            10. International Data Transfers
          </Grid>
          <Grid item component="p" className="privacytext">
            Currently, we do not transfer or store personal data outside of the
            user's home country. In the event that this changes, we will comply
            with applicable data protection laws and inform users accordingly.
          </Grid>
          <Grid item component="h4" mt={1} className="privacyheading">
            11. Contact Us
          </Grid>
          <Grid item component="p" className="privacytext">
            If you have any questions or concerns about this Privacy Policy or
            our data practices, please contact us at:
          </Grid>
          <Grid item component={"container"} mt={4}>
            <Grid item component="p" className="privacyheading">
            <strong> Krifly.com</strong> 
            </Grid>
            <Grid item component="p" mt={0.5} className="privacytext">
            Email: krifly@krify.com
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
};

export default Privacypolicy;
