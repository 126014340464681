import React from "react";
import { Grid, Typography } from "@mui/material";
import OneToOne from '../assets/onetoone.svg'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useNavigate } from "react-router-dom";
export const EventType = () => {
    const navigate =  useNavigate();
    const handleClik = () => {
        navigate('/createEvent');
    }
    return (
        <>
        <Grid sx={{height:'100%'}}>
                <Grid container alignItems={'center'} sx={{height:'15%'}}>
                    <Grid item component={'h1'} className='pageTitle'>Create New Event</Grid>
                </Grid>
            <Grid pt={5}>
                <Grid item md={5} className="newEventCard" onClick={handleClik} >
                    <Grid container justifyContent={'space-between'} alignItems={'center'}>
                        <Grid item md={3} sx={{textAlign:'center'}}>
                            <img src={OneToOne} alt="avatar"/>
                        </Grid>
                        <Grid item md={7}>
                            <Grid>
                                <Typography className="eventType">One-on-One</Typography>
                                <Typography className="eventDescription">One host with one invite</Typography>
                                <Typography className="eventDescription1">Good for: coffee chats, 1:1 interviews, etc</Typography>
                            </Grid>
                        </Grid>
                        <Grid item md={2} sx={{textAlign:'center'}}>
                            <KeyboardArrowRightIcon/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        
        </>
    )
}