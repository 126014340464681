// utils/formatUtils.js

export const formatDuration = (duration) => {
    if (!duration) {
      return "Unknown duration"; // or another default value
    }
  
    if (duration.includes("hours")) {
      const [hours] = duration.split(' ');
      return hours === "1" ? "1 hr" : `${hours} hrs`;
    } else if (duration.includes("mins")) {
      const [minutes] = duration.split(' ');
      const mins = parseInt(minutes, 10);
      
      if (mins === 60) {
        return "1 hr";
      } else if (mins < 60) {
        return `${mins} min${mins > 1 ? 's' : ''}`;
      } else {
        const hours = Math.floor(mins / 60);
        const remainder = mins % 60;
        return `${hours} hr${hours > 1 ? 's' : ''} ${remainder} min${remainder > 1 ? 's' : ''}`;
      }
    }
    
    return duration; // Return as is if not recognized
  };
  