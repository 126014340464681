import axios from 'axios';
import { apiConfig } from '../config/index';

const AxiosClient = axios.create({
    baseURL: apiConfig.baseUrl,
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json',
    },
});

const AxiosClientGoogle = axios.create({
    baseURL: apiConfig.baseUrlGoogle,
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json',
    },
});

export { AxiosClient, AxiosClientGoogle };
