import React, { useState } from "react";
import { Container, Grid, FormLabel, Button, TextField } from "@mui/material";
import { Header } from "../../components/layout_components/header";
import { useNavigate } from "react-router-dom";
import LoginImg from '../../assets/login.jpg';
import { AuthServices } from "../../services/authServices";
import Footer from "../../components/Footer";

export const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');

    // Validate email format
    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        if (email.trim() === '') {
            setError("Please enter your email.");
        } else if (!validateEmail(email)) {
            setError("Please enter a valid email address.");
        } else {
            setError("");
            try {
                const response = await AuthServices.userSignIn({ email: email });
                console.log(response);
                localStorage.setItem('email', response.data.email);

                if (response.status === 1) {
                    navigate('/welcomeBackLogin');
                }
                else{
                     navigate('/signup');
                }
            } catch (err) {
                console.error('Failed to check email', err);
                setError("An error occurred while checking the email.");
            }
        }
    };
    

    return (
        <>
            <Grid sx={{ height: '15vh' }}>
                <Header />
            </Grid>
            <Grid sx={{ height: '85vh' }} container justifyContent={'center'} alignItems={'center'}>
                <Container>
                    <Grid container justifyContent={'space-between'} alignItems={'center'}>
                        <Grid item md={5}>
                            <Grid component={'h1'} className="WelcomeHeading1">
                                <Grid component={'span'} className="">Welcome back to </Grid>
                                <Grid component={'span'} className="WelcomeHeading1Span"> 
                                    <Grid component={'span'} sx={{color:'#27AAE1'}}>Krif</Grid>
                                    <Grid component={'span'} sx={{color:'#006BFF'}}>ly</Grid> 
                                </Grid>
                            </Grid>
                            <Grid className="welcomeTextBlock">
                                <Grid component={'p'} className="welcomePara">
                                    Log in to your account to get back to your hub for scheduling meetings.
                                </Grid>
                                <Grid>
                                    <Grid component={'form'} onSubmit={handleLogin}>
                                        <FormLabel component={'p'} className="formLabel">Email Address</FormLabel>
                                        <Grid pb={4}>
                                            <Grid className="formBlock">
                                                <TextField 
                                                    placeholder="Enter your email" 
                                                    className="inputField"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    autoComplete="email"
                                                />
                                                <Grid>
                                                    <Button type="submit">Log in</Button>
                                                </Grid>
                                            </Grid>
                                            <Grid component={'span'} className="errorMsg">
                                                {error}
                                            </Grid>
                                        </Grid>
                                        <Grid>
                                            <Grid component={'span'} className="accountText">Don’t have an account? </Grid>
                                            <Grid component={'span'} className="accountTextSpan" onClick={() => {navigate('/signup');}}>Sign Up </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={6} container justifyContent={'center'} alignItems={'center'}>
                            <img src={LoginImg} alt="img" />
                        </Grid>
                    </Grid>
                </Container>
                <Footer />
            </Grid>
        </>
    );
};
