import React from 'react';
import { useLocation } from 'react-router-dom';

export const PageNotFound = () => {
  const location = useLocation();
  const validPaths = ['/', '/login', '/signup', '/welcomeBackLogin', '/privacypolicy', '/termsandconditions'];

  if (validPaths.includes(location.pathname)) {
    return null; 
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <h1>404 - Page Not Found</h1>
      <p>Sorry, the page <strong>{location.pathname}</strong> you are looking for does not exist.</p>
    </div>
  );
};
