import React from 'react';
import { Button, Container, Grid, Typography,Popover } from '@mui/material';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import { useState } from 'react';
import DoneIcon from '@mui/icons-material/Done';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useNavigate } from "react-router-dom";
import { CustomPopover } from '../popover';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
export const InnerHeaderComponent = ({ linkToCopy,myLinkName,eventId }) => {
    const navigate = useNavigate();
    const [copyText, setCopyText] = useState("Copy Link");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const editEvent = () => {
        navigate(`/editEvent/${myLinkName}/${eventId}`);
      }
      const homeEvent = () => {
        navigate('/app/events')
      }
    const popoverItems = [
        {
            icon: HomeOutlinedIcon,
            text: 'home',
            onClick:homeEvent,
        },
        {
            icon: ModeEditOutlineOutlinedIcon,
            text: 'Edit event',
            onClick: editEvent,
        },
    ];
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    
    
    const handleCopy = (event, id) => {
        event.stopPropagation();
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(linkToCopy)
                .then(() => {
                    setCopyText("Copied"); // Show 'Link Copied'
                    setTimeout(() => {
                        setCopyText("Copy Link"); // Revert to 'Copy' after 2 seconds
                    }, 2000);
                })
                .catch((err) => {
                    console.error('Failed to copy: ', err);
                });
        } else {
            // Fallback method for copying
            const textArea = document.createElement("textarea");
            textArea.value = linkToCopy;
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            
            try {
                const successful = document.execCommand('copy');
                if (successful) {
                    setCopyText("Copied"); // Show 'Link Copied'
                    setTimeout(() => {
                        setCopyText("Copy Link"); // Revert to 'Copy' after 2 seconds
                    }, 2000);
                } else {
                    console.error('Fallback: Oops, unable to copy');
                }
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err);
            }
    
            document.body.removeChild(textArea);
        }
    };
  
    return(
        <>
         <Grid className="eventSlotHeader" container alignItems={'center'}>
            <Container>
                <Grid container alignItems={'center'} justifyContent={'center'}>
                    <Grid item md={9} container alignItems={'center'} justifyContent={'end'} gap={2}>
                        <Grid item>
                            <Button container className="menuText" gap={3} aria-describedby={id} onClick={handleClick}>
                                <Grid>
                                    <Grid component={'span'}>Menu</Grid>
                                </Grid>
                                <Grid mt={1}>
                                    <ArrowDropDownOutlinedIcon />
                                </Grid>
                            </Button>
                        </Grid>
                        <Grid item >
                            <Button className="btn createEventBtn" onClick={handleCopy}>
                                { copyText === "Copied" ?
                                    ( <Typography className='copyText1'> <DoneIcon/>{copyText}</Typography>):
                                    ( <Typography className='copyText1'> <ContentCopyIcon/>{copyText}</Typography> )
                                }
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Grid>
        <CustomPopover
            id="popoverId1"
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            handleClose={handleClose}
            items={popoverItems}
        /> 

            
        
        </>
    )
}