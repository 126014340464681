import React, { useState } from "react";
import { Grid, Typography,TextField,Button,IconButton} from "@mui/material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { FormControl, MenuItem, Select } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from "react-router-dom";
import Checkbox from '@mui/material/Checkbox';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import Autocomplete from '@mui/material/Autocomplete';
const generateTimeIntervals = () => {
    const intervals = [];
    let start = new Date();
    start.setHours(0, 0, 0, 0);
  
    while (start.getDate() === new Date().getDate()) {
      intervals.push(start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }));
      const end = new Date(start);
      end.setMinutes(start.getMinutes() + 15);
      start = end;
    }
  
    return intervals;
  };
  
  const timeIntervals = generateTimeIntervals();
export const TimeSchedule = ({timeEdit}) => {

    const navigate =  useNavigate();

    const [hours, setHours] = useState('2'); 
    const [startTime, setStartTime] = useState('9:00 AM'); 
    const [endTime, setEndTime] = useState('6:30 PM');
    const [timeSheet, setTimeSheet] = useState(false);
    const [timeData, setTimeData] = useState(true);
    const [showCrossButton, setShowCrossButton] = useState(true);
    const [showAddButton, setShowAddButton] = useState(false);

  const handleChangeHours = (event) => {
    setHours(event.target.value);
    if (event.target.value === '1') {
        setTimeSheet(true);
        setTimeData(false)
    } 
    else if(event.target.value === '2') {
        setTimeSheet(false);
        setTimeData(true)

    }
    
  };
  


  const handleCrossClick = () => {
    setShowCrossButton(false);
    setShowAddButton(true)
  };

  const handleAddClick = () => {
    setShowCrossButton(true);
    setShowAddButton(false)
  };



    return (
        <>
            <Grid conatiner justifyContent={'space-between'} >
                <Grid item p={4} className="createEventSidenavHeader">
                    <Grid container >
                        <Grid container item sx={{cursor:'pointer'}} onClick={timeEdit} >
                            <KeyboardArrowLeftIcon/>
                            <Typography >Cancel</Typography>
                        </Grid>
                    </Grid>
                    <Grid item component={'h1'} className='newEventType'>Profile Name</Grid>
                </Grid>
                <Grid item className="createEventSidenavHeader" >
                    <Grid>
                        <Typography className="dateLabel">Available hours</Typography>
                        <Typography pt={2}>Set the times that people will be able to schedule these types of meetings with you.</Typography>
                    </Grid>
                    <Grid container alignContent={'center'} gap={2} pt={3}>
                        <Grid item md={6}>
                            <FormControl fullWidth>
                                <Select
                                    labelId="time-select-label"
                                    id="time-select"
                                    value={hours}
                                    onChange={handleChangeHours}
                                    IconComponent={ExpandMoreIcon}
                                    sx={{ borderRadius: '10px' }}
                                    displayEmpty
                                
                                    className="eventSelectField"
                                    >
                                    <MenuItem value="1">
                                        Working hours
                                    </MenuItem>
                                    <MenuItem value="2">
                                        Custom hours
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item p={4}>
                    <Grid>
                        <Typography className="dateLabel">Weekly hours</Typography>
                        {timeData&&<Grid container alignItems={'center'} gap={2} pt={3}>
                            <Grid item md={1}>
                                <Typography className="weekDays">SUN</Typography>
                            </Grid>
                            <Grid item md={9}>
                                {showCrossButton ? (
                                <Grid container alignItems={'center'} gap={1}>
                                    <Grid item md={5}>
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                options={timeIntervals}
                                                getOptionLabel={(option) => option}
                                                value={startTime}
                                                onChange={(event, newValue) => setStartTime(newValue)}
                                                renderInput={(params) => 
                                                <TextField 
                                                {...params} 
                                                variant="outlined" 
                                                fullWidth 
                                                size="medium" 
                                                className="eventTextField autoCompleteField" 
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: null // Remove the clear icon
                                                }} 
                                                />
                                                }
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={1}>to</Grid>
                                    <Grid item md={5}>
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                options={timeIntervals}
                                                getOptionLabel={(option) => option}
                                                value={endTime}
                                                onChange={(event, newValue) => setEndTime(newValue)}
                                                renderInput={(params) => 
                                                    <TextField 
                                                    {...params} 
                                                    variant="outlined" 
                                                    fullWidth 
                                                    size="medium" 
                                                    className="eventTextField autoCompleteField" 
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: null // Remove the clear icon
                                                    }} 
                                                    />
                                                }
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                ) : (
                                <Typography>Unavailable</Typography>
                                )}
                            </Grid>
                            {showCrossButton && ( 
                                <Grid item md={0.5}>
                                    <IconButton onClick={handleCrossClick}>
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                            )}
                           {showAddButton &&  
                           <Grid item md={0.5}>
                                <IconButton onClick={handleAddClick}>
                                    <AddIcon />
                                </IconButton>
                                
                            </Grid>}
                            
                        </Grid>}
                        {timeSheet && <Grid container alignItems={'center'} gap={2} pt={3}>
                            <Grid item md={3}>
                                <Grid className="weekDays">SUN</Grid>
                            </Grid>
                            <Grid item md={8}>
                                <Grid>Unavailable</Grid>
                            </Grid>
                        </Grid>}
                     </Grid>
                </Grid>
                <Grid item p={4}>
                    <Grid container justifyContent={'end'} alignItems={'center'}>
                        <Typography sx={{cursor:'pointer'}} onClick={() => navigate('/app/events')}>Cancel</Typography>
                        <Button className="continueBtn">Save and close</Button>
                    </Grid>
                </Grid>
            </Grid>
                
        </>
    )
};