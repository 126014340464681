import React,{useEffect} from 'react';
import { Container, Grid } from '@mui/material';
import { Header } from '../components/layout_components/header';
import '../styles/style.css';
import { GoogleLoginButton } from '../components/googleLogin';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
export const Home = () => {
const navigate = useNavigate();
  useEffect(() => {
    const isAuthenticated = JSON.parse(localStorage.getItem('isAuthenticated'));
    if (isAuthenticated === true) {
     return navigate('/app/events');
    }
  }, []);

  return (
    <>
      <Grid sx={{ height: '15vh' }}>
        <Header />
      </Grid>
      <Grid sx={{ height: '85vh' }} container justifyContent={'center'} alignItems={'center'}>
        <Container className='backGroundImg'>
          <Grid component={'h1'} className='heading1'>Easy scheduling ahead</Grid>
          <Grid component={'h1'} className='heading2'>Scheduling becomes quick, hassle-free, and tailored to everyone’s convenience.</Grid>
          <Grid component={'p'} className='paragraph1'>
          Krifly is an intuitive scheduling platform designed to simplify appointment management for individuals and businesses by eliminating the time wasted in back-and-forth communications when finding a suitable meeting time. With our tool, users can set up their availability and share a meeting link with clients, colleagues, or friends, allowing the other party to easily choose a time slot that suits their convenience. <br/><br/>
          Our platform seamlessly integrates with Google Calendar, ensuring no double bookings, while offering customizable meeting types, durations, and notifications. Initially focused on Gmail and Google Workspace accounts. <br/><br/>
          Krifly.com is perfect for professionals seeking an efficient, automated solution for organizing meetings and optimizing time management.
          </Grid>
          {/* <Grid component={'p'} className='paragraph2'>Sign up free with Google</Grid> */}
          <Grid className='socialIcons'>
            <GoogleLoginButton />
          </Grid>
          
          {/* <Grid className='orDiv'>
            <Grid component={'p'} className='orText'>OR</Grid>
          </Grid>
          <Grid className='socialText'>
            <Grid component={'span'} className='span1'>
              <Link underline="none" href="/signup">Sign up free with email.</Link>
            </Grid>
            <Grid component={'span'} className='span2'>No credit card required</Grid>
          </Grid> */}
        </Container>
        
          {/* <Grid Container> */}
        <Footer/>
          {/* </Grid> */}
      </Grid>
    </>
  );
};
