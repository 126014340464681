import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import MeetIcon from "../assets/meeticon.svg";
import { ManagementServices } from "../services/managementServices";
import { SuccessAlert, ErrorAlert } from "../components/alert";

export const EditEventDetails = ({
  detailsEdit,
  eventDetails,
  updateEventDetails,
}) => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [time, setTime] = useState("15 mins");
  const [color, setColor] = React.useState("#8247F5");
  const {
    control, handleSubmit, formState, watch, reset, setValue
  } = useForm({
    defaultValues: {
      eventName: "",
      customDuration: "",
      customUnit: "mins",
    },
    mode: "onChange", // This will validate the form on each change
  });
  const { errors, isValid } = formState;
  const [alrtMsg, setAlrtMsg] = useState("");
  const [successAlrtOpen, setSuccessAlrtOpen] = useState(false);
  const [errorAlrtOpen, setErrorAlrtOpen] = useState(false);

  const closeSuccess = () => {
    setSuccessAlrtOpen(false);
  };

  const closeError = () => {
    setErrorAlrtOpen(false);
  };

  const handleChange = (event) => {
    setTime(event.target.value);
  };
  
  const handleChangeColor = (event) => {
    setColor(event.target.value);
  };

  const onSubmit = async (data) => {
    if (!formState.isValid) {
      return; // Prevent submission if the form is not valid
    }
  
    const payload = {
      eventId: eventDetails.id,
      name: data.eventName,
      duration:
        time === "custom" ? `${data.customDuration} ${data.customUnit}` : time,
      location: eventDetails.location,
      colorCode: color,
    };
    try {
      const response = await ManagementServices.updateEventDetails(payload);
      if (response.status === 1) { 
        setSuccessAlrtOpen(true);
        setAlrtMsg(response.message);
        reset({
          eventName: "",
          customDuration: "",
          customUnit: "mins",
          color: "#8247F5",
        });
        setTimeout(() => {
          navigate("/app/events");
        }, 500);
      } else {
        setErrorAlrtOpen(true);
        setAlrtMsg(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (eventDetails) {
      const { durationWithFormat, name, colorCode } = eventDetails;
      const predefinedDurations = ["15 mins", "30 mins", "45 mins", "60 mins"];
  
      if (predefinedDurations.includes(durationWithFormat)) {
        setTime(durationWithFormat);
        setValue("customDuration", ""); // Clear custom duration
        setValue("customUnit", "mins"); // Default to "mins"
      } else {
        // Handle custom duration
        setTime("custom");
        const [duration, unit] = durationWithFormat.split(" ");
        setValue("customDuration", duration || ""); // Set custom duration value
        setValue("customUnit", unit || "mins");     // Set custom unit value
      }
  
      reset({
        eventName: name,
        customDuration: durationWithFormat && !predefinedDurations.includes(durationWithFormat) ? durationWithFormat.split(" ")[0] : "",
        customUnit: durationWithFormat && !predefinedDurations.includes(durationWithFormat) ? durationWithFormat.split(" ")[1] : "mins",
      });
  
      setColor(colorCode || "#8247F5");
    }
  }, [eventDetails, reset, setValue]);
  
  

  return (
    <>
      <Grid container justifyContent={"space-between"}>
        <Grid item p={4} className="createEventSidenavHeader" md={12}>
          <Grid container>  
            <Grid
              container
              item
              sx={{ cursor: "pointer" }}
              onClick={detailsEdit}
            >
              <KeyboardArrowLeftIcon />
              <Typography>Cancel</Typography>
            </Grid>
          </Grid>
          <Grid item component={"h1"} className="newEventType">
            Event details
          </Grid>
        </Grid>
        <Grid component={"form"} md={12} onSubmit={handleSubmit(onSubmit)}>
          <Grid item p={4}>
            <Grid className="labelName">Event name</Grid>
            <Grid container alignItems={"center"}>
              <Grid item md={3}>
                <FormControl fullWidth className="formControl">
                  <Select
                    labelId="time-select-label"
                    fullWidth
                    id="time-select"
                    value={color} 
                    onChange={handleChangeColor}
                    IconComponent={ExpandMoreIcon}
                    sx={{
                      borderRadius: "10px 0 0 10px",
                      backgroundColor: "white",
                      height: "45px",
                      marginTop: "-10px",
                      "& .MuiSelect-select": {
                        display: "flex",
                        alignItems: "center",
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: "300px", 
                          padding: 0,
                          "& .MuiMenuItem-root": {
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flex: "1 0 20%",
                          },
                        },
                      },
                    }}
                    placeholder="Select time"
                  >
                    <MenuItem value={"#FF4F00"}>
                      <Grid className={"color1"}></Grid>
                    </MenuItem>
                    <MenuItem value={"#FF758E"}>
                      <Grid className={"color2"}></Grid>
                    </MenuItem>
                    <MenuItem value={"#E55CFF"}>
                      <Grid className={"color3"}></Grid>
                    </MenuItem>
                    <MenuItem value={"#8247F5"}>
                      <Grid className={"color4"}></Grid>
                    </MenuItem>
                    <MenuItem value={"#0099FF"}>
                      <Grid className={"color5"}></Grid>
                    </MenuItem>
                    <MenuItem value={"#0AE8F0"}>
                      <Grid className={"color6"}></Grid>
                    </MenuItem>
                    <MenuItem value={"#17E885"}>
                      <Grid className={"color7"}></Grid>
                    </MenuItem>
                    <MenuItem value={"#ccf000"}>
                      <Grid className={"color8"}></Grid>
                    </MenuItem>
                    <MenuItem value={"#f8e436"}>
                      <Grid className={"color9"}></Grid>
                    </MenuItem>
                    <MenuItem value={"#ffa600"}>
                      <Grid className={"color10"}></Grid>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={9}>
                <FormControl fullWidth>
                  <Controller
                    name="eventName"
                    control={control}
                    rules={{ required: "Event name is required" }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        placeholder="Name your event"
                        fullWidth
                        size="medium"
                        className=" event1"
                        error={!!errors.eventName}
                        helperText={errors.eventName?.message}
                        InputProps={{
                          sx: {
                            borderRadius: "0 10px 10px 0", 
                          },
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <FormControl fullWidth className="formControl">
              <Grid className="labelName">Duration</Grid>
              <Select
                labelId="time-select-label"
                id="time-select"
                value={time}
                onChange={handleChange}
                IconComponent={ExpandMoreIcon}
                sx={{ borderRadius: "10px" }}
                className="eventSelectField"
              >
                <MenuItem value="15 mins">15 mins</MenuItem>
                <MenuItem value="30 mins">30 mins</MenuItem>
                <MenuItem value="45 mins">45 mins</MenuItem>
                <MenuItem value="60 mins">60 mins</MenuItem>
                <MenuItem value="custom">Custom</MenuItem>
              </Select>
            </FormControl>
            {time === "custom" && (
              <Grid container spacing={2} style={{ marginTop: "10px" }}>
                <Grid item xs={6}>
                  <Controller
                    name="customDuration"
                    control={control}
                    rules={{ required: "Custom duration is required" }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Custom Duration"
                        variant="outlined"
                        fullWidth
                        type="number"
                        error={!!errors.customDuration || !!error}
                        helperText={errors.customDuration?.message || error}
                        onChange={(e) => {
                          const value = Number(e.target.value);
                          field.onChange(e);
                          if (watch("customUnit") === "hours" && value > 12) {
                            setError("Must be less than or equal to 12 hours");
                          } else {
                            setError("");
                          }
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="customUnit"
                    control={control}
                    rules={{ required: "Custom unit is required" }}
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <Select
                          {...field}
                          variant="outlined"
                          sx={{ borderRadius: "10px" }}
                          onChange={(e) => {
                            const value = e.target.value;
                            field.onChange(e);
                            if (
                              value === "hours" &&
                              watch("customDuration") > 12
                            ) {
                              setError(
                                "Must be less than or equal to 12 hours"
                              );
                            } else {
                              setError("");
                            }
                          }}
                        >
                          <MenuItem value="mins">Mins</MenuItem>
                          <MenuItem value="hours">Hours</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>
            )}
            <FormControl fullWidth className="formControl">
              <Grid className="labelName">Location</Grid>
              <Grid container gap={1} pt={2}>
                <Grid item className="meetIcon">
                  <img src={MeetIcon} alt="meetIcon" />
                </Grid>
                <Grid item className="timeText">
                  {eventDetails?.location}
                </Grid>
              </Grid>
            </FormControl>
          </Grid>
          <Grid item p={4}>
            <Grid
              container
              justifyContent={"end"}
              alignItems={"center"}
              gap={2}
            >
              <Typography
                sx={{ cursor: "pointer" }}
                onClick={() => navigate("/app/events")}
              >
                Cancel
              </Typography>
              <Button className="continueBtn" type="submit" disabled={!formState.isValid}>
                Continue
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <SuccessAlert
        alertMsg={alrtMsg}
        open={successAlrtOpen}
        close={closeSuccess}
      />
      <ErrorAlert alertMsg={alrtMsg} open={errorAlrtOpen} close={closeError} />
    </>
  );
};
