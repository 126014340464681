import React, { useState, useEffect } from "react";
import { Grid, Typography, Link } from "@mui/material";
import { Card } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useNavigate } from "react-router-dom";
import { ManagementServices } from "../services/managementServices";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {PageNotFound} from '../pages/notFound';
export const LinkDetails = () => {
  const location = useLocation();

  const [events, setEvents] = useState([]);
  const [userDetails, setUserDetails] = useState({});

  const navigate = useNavigate();
  const { myLinkName } = useParams();
  const userData = JSON.parse(localStorage.getItem("user") || "{}");
  const userList = async () => {
    if (myLinkName) {
      try {
        const response = await ManagementServices.LinkDetails(myLinkName);
        setEvents(response.data.events);
        setUserDetails(response.data.user);
      } catch (error) {
        console.error(error);
      }
    } else {
      console.error("myLinkName is not provided");
    }
  };
  const handleEventSlot = (events) => {
    const arrowIcon = {
      arrow: true,
    };
    navigate(`/${myLinkName}/${events.uniqueId}`, { state: arrowIcon });
  };
  const AllEvents = events.map((event) => {
    return (
      <Grid item md={5.5} mt={3} onClick={() => handleEventSlot(event)}>
        <Card>
          <Grid
            container
            justifyContent={"space-between"}
            p={4}
            className="cardBox"
          >
            <Grid item>
              <Grid container gap={2}>
                <Typography
                  className="bullet"
                  sx={{ backgroundColor: event?.colorCode }}
                ></Typography>
                <Typography gutterBottom className="linkProfileEvent">
                  {event?.name}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <ArrowDropDownIcon />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    );
  });

  useEffect(() => {
    userList();
  }, []);

  return (
    <>
      {location.state && location.state.linkName === myLinkName ? (
        <Grid
          container
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ height: "100vh" }}
        >
          <Grid
            md={9}
            my={4}
            container
            alignItems={"center"}
            sx={{ height: "80%" }}
          >
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"}
              sx={{ height: "100%" }}
            >
              <Grid md={9} className="createCard" p={5} height={"100%"}>
                <Grid sx={{ height: "100%" }}>
                  <Grid sx={{ height: "30%" }}>
                    <Link
                      href={
                        userData
                          ? `${window.location.origin}/app/events`
                          : `${window.location.origin}`
                      }
                    >
                      <Grid className="overflowBranding">
                        <Grid className="brnadingBlock">
                          <Typography>powered by</Typography>
                          <Typography>Krifly</Typography>
                        </Grid>
                      </Grid>
                    </Link>
                    <Grid className="linkProfileHeader">
                      {userDetails?.profilePic && (
                        <Grid className="profileLetter profilePictureBlock">
                          <img src={userDetails?.profilePic} />
                        </Grid>
                      )}
                      <Typography className="linkProfileName">
                        {userDetails.name}
                      </Typography>
                      <Typography className="linkProfileText">
                        Welcome to my scheduling page. Please follow the
                        instructions to add an event to my calendar.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    gap={1.5}
                    sx={{
                      height: "70%",
                      overflow: "auto",
                      paddingRight: "15px",
                    }}
                  >
                    {AllEvents.length === 0 ? (
                      <Grid item xs={12}>
                        <Typography
                          component={"h1"}
                          className="pageTitle"
                          sx={{ textAlign: "center", fontSize: "20px" }}
                        >
                          No events available
                        </Typography>
                      </Grid>
                    ) : (
                      AllEvents
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <PageNotFound/>
      )}
    </>
  );
};
