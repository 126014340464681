// LogoutModal.js
import React from 'react';
import { Modal, Box, Typography, Grid, Button } from '@mui/material';

const LogoutModal = ({ open, handleClose, handleLogout,text }) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow:' rgba(100, 100, 111, 0.2)',
        outline:0,
        p: 4,
        borderRadius:'10px',
        
      };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography sx={{ textAlign: 'center' }} id="modal-modal-title" variant="h6" component="h2">
         {text}
        </Typography>
        <Grid container pt={5} justifyContent={'space-around'}>
          <Grid item md={3.5}>
            <Button className="deleteBtn" sx={{width:'100%'}} onClick={handleLogout}>Yes</Button>
          </Grid>
          <Grid item md={3.5}>
            <Button className="createEventBtn" sx={{width:'100%'}} onClick={handleClose}>No</Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default LogoutModal;
