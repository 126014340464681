import React, { useEffect, useState } from 'react';
import { 
    Grid,
    TextField,
    InputAdornment,
    Box,
    Typography,
} from '@mui/material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import SearchIcon from '@mui/icons-material/Search';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ManagementServices } from '../services/managementServices';
import EmptyCalendarImg from '../assets/emptyCalendar.svg';
import Tooltip from '@mui/material/Tooltip';

export const ScheduledEvents = () => {
    const [value, setValue] = useState('future');
    const [expanded, setExpanded] = useState(false);
    const user = JSON.parse(localStorage.getItem('user'));
    const [events, setEvents] = useState([]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        getSheduleEvents(newValue);
    };

    const handleAccordionChange = (index) => (event, isExpanded) => {
        setExpanded(isExpanded ? index : false);
    };

    const getSheduleEvents = async (eventTense) => {
        const payload = {
            googleId: user.googleId,
            userId: user.id,
            eventTense: eventTense
        };
        try {
            const response = await ManagementServices.ScheduledEvents(payload);
            if (response.status === 1) {
                setEvents(response.data);
            } else {
                setEvents([]); // Ensure to clear events if no data found
            }
        } catch (error) {
            console.error(error);
            setEvents([]); // Ensure to clear events in case of an error
        }
    };

    useEffect(() => {
        getSheduleEvents(value);
    }, [value]);

    const eventElements = Object.entries(events).map(([date, events]) => (
        <Grid key={date} className="date-section">
            <Grid item className='dateBlock'>
                <Typography className='tabsText'>{date}</Typography>
            </Grid>
            {events.map((event, index) => (
                <Grid key={index} className='tabPanelBlock'>
                    <Grid>
                        <Grid container alignItems={'center'} gap={2} className='eventTabBlock'>
                            <Grid item md={2.5}>
                                <Grid container gap={2} alignItems={'center'}>
                                    <Typography className="bullet" sx={{ backgroundColor: event.colorCode }}></Typography>
                                    <Typography>{`${event.startTime} - ${event.endTime}`}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item md={5.5}>
                                <Typography className='tabsText'>{event.name}</Typography>
                                <Typography>
                                    <Typography component={'span'}>Event type </Typography>
                                    <Typography component={'span'} className='tabsText'>{event.eventName}</Typography>
                                </Typography>
                            </Grid>
                            <Grid item md={2}>
                                <Typography><Tooltip placement="top" title={event.organizerName || ''}>
                                    <Typography component={'span'}>1 host</Typography>
                                </Tooltip>
                                                         | 0 non-hosts</Typography>
                            </Grid>
                            <Grid item md={1}>
                                <Accordion className='accordianBlock' expanded={expanded === index} onChange={handleAccordionChange(index)}>
                                    <AccordionSummary
                                        expandIcon={<ArrowDropDownIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <Typography>Details</Typography>
                                    </AccordionSummary>
                                </Accordion>
                            </Grid>
                            {expanded === index && (
                                <Grid container>
                                    <Grid item md={6}>
                                        <AccordionDetails>
                                            <Grid>
                                                <Grid pt={3}>
                                                    <Typography className='tabsText'>EMAIL</Typography>
                                                    <Typography>{event.email}</Typography>
                                                </Grid>
                                                <Grid pt={3}>
                                                    <Typography className='tabsText'>Location</Typography>
                                                    <Typography>{event.eventLocation}</Typography>
                                                </Grid>
                                                <Grid pt={3}>
                                                    <Typography className='tabsText'>INVITE TIME ZONE</Typography>
                                                    <Typography>India Standard Time</Typography>
                                                </Grid>
                                                <Grid pt={3}>
                                                    <Typography className='tabsText'>MEETING HOST</Typography>
                                                    <Typography pt={1} sx={{ color: 'rgba(26, 26, 26, 0.61)' }}>
                                                        Host will attend this meeting
                                                    </Typography>
                                                    <Grid pt={1}>
                                                        <Grid className="profileLetter profilePictureBlock">
                                                        <Tooltip placement="top" title={event.organizerName || ''}
                                                        >
                                                        {event&&event.profilePic?(
                                                                    <img src={event.profilePic} alt="profile" />
                                                                ):(
                                                                    <Typography component={'span'}>
                                                                    {event.organizerName ? event.organizerName.charAt(0) : ''}
                                                                </Typography>
                                                                )
                                                        }
                                                            
                                                        </Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item pt={3}>
                                                    <Typography pt={1} sx={{ color: 'rgba(26, 26, 26, 0.61)' }}>
                                                        {event.createdInfo}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            ))}
            
        </Grid>
       
    ));

    return (
        <Grid sx={{ height: '100%' }}>
            <Grid container justifyContent={'space-between'} alignItems={'center'} sx={{ height: '15%' }}>
                <Grid item component={'h1'} className='pageTitle'>Meetings</Grid>
                {/* <Grid item>
                    <TextField
                        className='searchTextField'
                        size='small'
                        variant="outlined"
                        placeholder="Find event types"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid> */}
            </Grid>
            <Grid sx={{ height: '85%',overflowY: 'auto',overflowX: 'hidden' }}>
                <Box sx={{ width: '100%', typography: 'body1' }} className={'tabsBlock'}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label="Upcoming" value="future" className='scheduleTabs'/>
                                <Tab label="Pending" value="pending" className='scheduleTabs'/>
                                <Tab label="Past" value="past" className='scheduleTabs'/>
                            </TabList>
                        </Box>
                        <TabPanel value="future" className='tabPanelBlock'>
                            {events && Object.keys(events).length > 0 ? (
                                <Grid>
                                    {eventElements}
                                    <Grid className="reachedText">You've reached the end of the list</Grid>
                                </Grid>
                            ) : (
                                <Grid container direction="column" alignItems="center" justifyContent="center" sx={{ paddingTop: '50px', paddingBottom: '50px' }}>
                                    <img src={EmptyCalendarImg} alt="calendar" />
                                    <Typography className='eventStatusText'>No Future Events</Typography>
                                </Grid>
                            )}
                        </TabPanel>
                        <TabPanel value="pending" className='tabPanelBlock'>
                            {events && Object.keys(events).length > 0 ? (
                                <Grid>
                                    {eventElements}
                                    <Grid className="reachedText">You've reached the end of the list</Grid>
                                </Grid>
                            ) : (
                                <Grid container direction="column" alignItems="center" justifyContent="center" sx={{ paddingTop: '50px', paddingBottom: '50px' }}>
                                    <img src={EmptyCalendarImg} alt="calendar" />
                                    <Typography className='eventStatusText'>No Pending Events</Typography>
                                </Grid>
                            )}
                        </TabPanel>
                        <TabPanel value="past" className='tabPanelBlock'>
                        {events && Object.keys(events).length > 0 ? (
                            <Grid>
                                {eventElements}
                                <Grid className="reachedText">You've reached the end of the list</Grid>
                            </Grid>
                        ) : (
                            <Grid container direction="column" alignItems="center" justifyContent="center" sx={{ paddingTop: '50px', paddingBottom: '50px' }}>
                                <img src={EmptyCalendarImg} alt="calendar" />
                                <Typography className='eventStatusText'>No Past Events</Typography>
                            </Grid>
                        )}
                            
                        </TabPanel>
                    </TabContext>
                </Box>
            </Grid>
        </Grid>
    );
}
