import React from "react";
import { Route, Routes } from "react-router-dom";
import { SideNav } from "../components/layout_components/sidenav";
import { Home } from "../pages/home";
import { Login } from "../pages/login/login";
import SignUp from "../pages/login/signup";
import { WelcomeBackLogin } from "../pages/login/welcomeBackLogin";
import { WelcomeBackSignUp } from "../pages/login/welcomeBackSignUp";
import { NormalSignUp } from "../pages/signUp";
import { NormalLogin } from "../pages/login";
import Privacypolicy from "../pages/Privacypolicy";
import Termsandconditions from "../pages/Termsandconditions";

export const CommonRoutes = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/sidenav" element={<SideNav />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/welcomeBackLogin" element={<WelcomeBackLogin />} />
                <Route path="/welcomeBackSignUp" element={<WelcomeBackSignUp />} />
                <Route path="/normalSignUp" element={<NormalSignUp />} />
                <Route path="/normalLogin" element={<NormalLogin />} />
                <Route path="/privacypolicy" element={<Privacypolicy/>} />
                <Route path="/termsandconditions" element={<Termsandconditions/>} />
            </Routes>
        </>
    );
};
