
import { IndividualRoutes } from "./individualRoutes";
import {CommonRoutes} from "./commonRoutes";
import { Router } from "react-router-dom";
const MainRouter = () => {
    return (
        <>
          
                <CommonRoutes />
                <IndividualRoutes />
            
        </>
    )
}

export default MainRouter;