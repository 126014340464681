import { Container, Grid } from "@mui/material";
import React from "react";
import Footer from "../components/Footer";
import { Header } from "../components/layout_components/header";

const Termsandconditions = () => {
  return (
    <>
      <Header />

      <Container>
        <Grid container direction="column" spacing={1} p={2}>
          <Grid item component="h3" className="privacymainheading">
            Terms and Conditions
          </Grid>
          <Grid item component="h4" className="privacyheading">
            Last Updated: 4th September 2024
          </Grid>
          <Grid item component="h4" mt={1} className="privacyheading">
            1. Acceptance of Terms
          </Grid>
          <Grid item component="p" className="privacytext">
            By accessing and using the website of Krifly ("we," "us," "our"),
            you ("User," "you," "your") agree to comply with and be bound by
            these Terms and Conditions ("Terms"). If you do not agree with any
            of these terms, you must not use this website.
          </Grid>
          <Grid item component="h4" mt={1} className="privacyheading">
            2. Intellectual Property
          </Grid>

          <Grid item component="p" className="privacytext">
            All content on this website, including but not limited to text,
            graphics, logos, images, and software, is the property of Krifly or
            its content suppliers and is protected by international copyright
            laws. You may not reproduce, distribute, modify, create derivative
            works, or use any content from this site without express written
            permission from Krifly.
          </Grid>

          <Grid item component="h4" mt={1} className="privacyheading">
            3. Use of Website
          </Grid>
          <Grid item component="p" className="privacytext">
            You agree to use this website and our services only for lawful
            purposes and in a manner that does not infringe the rights of,
            restrict, or inhibit anyone else's use and enjoyment of the website.
          </Grid>

          <Grid
            item
            component="ul"
            sx={{
              padding: 0,
              margin: 0,
              "& > li": {
                listStyleType: "disc", // This sets the bullet (dot) style
                marginBottom: (theme) => theme.spacing(1),
                marginLeft: 4, // Optional: Indent the bullet points
              },
            }}
          >
            <Grid item component="li" className="privacytext">
              You must not use our website or services for any unlawful or
              fraudulent purposes.
            </Grid>
            <Grid item component="li" className="privacytext">
              You must not attempt to gain unauthorized access to the website,
              the server on which the website is stored, or any server,
              computer, or database connected to our website.
            </Grid>
            <Grid item component="li" className="privacytext">
              You agree not to use the website in any way that could damage,
              disable, overburden, or impair the website or interfere with any
              other party's use of the website.
            </Grid>
          </Grid>
          <Grid item component="h4" mt={1} className="privacyheading">
            4. Limitation of Liability
          </Grid>
          <Grid item component="p" className="privacytext">
            Krifly and its affiliates, directors, employees, agents, partners,
            and licensors shall not be liable for any loss or damage, whether
            direct, indirect, incidental, special, consequential, or punitive,
            arising from your use of or reliance on information provided on this
            website or through our services. This includes, but is not limited
            to, damages for errors, omissions, interruptions, defects, or
            delays.
          </Grid>

          <Grid item component="h4" mt={1} className="privacyheading">
            5. Third-Party Links
          </Grid>
          <Grid item component="p" className="privacytext">
            Our website may contain links to third-party websites or services
            that are not owned or controlled by Krifly. We have no control over,
            and assume no responsibility for, the content, privacy policies, or
            practices of any third-party websites or services. You acknowledge
            and agree that Krifly shall not be responsible or liable, directly
            or indirectly, for any damage or loss caused or alleged to be caused
            by or in connection with the use of or reliance on any such content,
            goods, or services available on or through any such websites or
            services.
          </Grid>
          <Grid item component="h4" mt={1} className="privacyheading">
            6. Privacy Policy
          </Grid>
          <Grid item component="p" className="privacytext">
            Your use of our website is also governed by our Privacy Policy,
            which explains how we collect, use, and protect your personal
            information. By using our website, you consent to the practices
            described in the Privacy Policy.
          </Grid>

          <Grid item component="h4" mt={1} className="privacyheading">
            7. Governing Law
          </Grid>
          <Grid item component="p" className="privacytext">
            These Terms and Conditions are governed by and construed in
            accordance with the laws of India, specifically the state of Andhra
            Pradesh, without regard to its conflict of law principles. Any
            disputes arising out of or relating to these terms or your use of
            the website will be resolved exclusively in the courts located in
            Andhra Pradesh, India.
          </Grid>
          <Grid item component="h4" mt={1} className="privacyheading">
            8. Changes to These Terms
          </Grid>
          <Grid item component="p" className="privacytext">
            We reserve the right to modify these Terms and Conditions at any
            time. Any changes will be posted on this page, and it is your
            responsibility to review these terms periodically. Your continued
            use of the website following the posting of changes will constitute
            your acceptance of those changes.
          </Grid>
          <Grid item component="h4" mt={1} className="privacyheading">
            9. Termination
          </Grid>
          <Grid item component="p" className="privacytext">
            We reserve the right to terminate or suspend your access to our
            website or services immediately, without prior notice or liability,
            for any reason whatsoever, including, without limitation, if you
            breach these Terms. Upon termination, your right to use the website
            and services will cease immediately.
          </Grid>
          <Grid item component="h4" mt={1} className="privacyheading">
            10. Indemnification
          </Grid>
          <Grid item component="p" className="privacytext">
          You agree to indemnify, defend, and hold harmless Krifly and its affiliates, directors, employees, agents, partners, and licensors from and against any and all claims, losses, expenses, damages, and costs, including reasonable attorneys' fees, arising out of or related to your use of the website or services, your violation of these Terms, or your violation of any rights of another.
          </Grid>
          <Grid item component="h4" mt={1} className="privacyheading">
          11. Contact Information
          </Grid>
          <Grid item component="p" className="privacytext">
          If you have any questions or concerns about these Terms and Conditions, please contact us at:
          </Grid>
          <Grid item component={"container"} mt={4}>
            <Grid item component="p" className="privacyheading">
              Krifly.com
            </Grid>
            <Grid item component="p" mt={0.5} className="privacytext">
              Email: krifly@krify.com
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
};

export default Termsandconditions;
