import React from "react";
import { Grid, Typography,Container,Link} from "@mui/material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PublicIcon from '@mui/icons-material/Public';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import { useLocation } from 'react-router-dom';
import { InnerHeaderComponent } from "../components/layout_components/innerHeaderComponent";
export const EventScheduled = () => {
    const location = useLocation();
    const { sheduledData,eventId,formattedDuration,eventSlot } = location.state || {};
    const user = JSON.parse(localStorage.getItem('user'));
    const dynamicLink = user ? `${window.location.origin}/${user.myLinkName}/${eventId}` : '';
    
    return (
        <>
        <Grid sx={{height:'100vh'}}>
        {user && <InnerHeaderComponent
             linkToCopy={dynamicLink} 
             myLinkName={user.myLinkName}
             eventId={eventId}
             />}
            <Container className="containerAdjust"  sx={{ height: user ? '90%' : '100%' }}>
                <Grid py={5}  container alignItems={'center'} justifyContent={'center'} sx={{height:'100%'}}>
                    <Grid md={9}  className="createCard" p={5} sx={{height:'100%'}}>
                        <Grid container justifyContent={'center'} sx={{height:'100%'}}>
                            <Grid item md={6} sx={{height:'100%'}}>
                                <Grid sx={{height:'10%'}}>
                                    <Link href={user ? `${window.location.origin}/app/events` : `${window.location.origin}`}>
                                        <Grid className="overflowBranding">
                                            <Grid className="brnadingBlock">
                                                <Typography>powered by</Typography>
                                                <Typography>Krifly</Typography>
                                            </Grid>
                                        </Grid>
                                    </Link>
                                   
                                    <Grid container flexDirection={'column'} justifyContent={'center'} alignItems={'center'}> 
                                        {eventSlot?.profilePic&&<Grid className="profilePictureBlock profileLetter">
                                            <img src={eventSlot?.profilePic} alt="profile" />
                                        </Grid>}
                                        <Typography px={4} className="scheduledHeading">
                                            <CheckCircleIcon className="checkCircleIcon"/>
                                            You are scheduled
                                        </Typography>
                                        <Typography className="scheduledText">A calendar invitation has been sent to your email address.</Typography>
                                    </Grid>
                                    <Grid className="scheduledBox">
                                        <Grid className="scheduledEventName">{sheduledData?.eventName}</Grid>
                                        <Grid container gap={2} pt={3}>
                                            <PersonOutlinedIcon className="timeIcon"/>
                                            <Typography className="timeText">{sheduledData?.organizerName}</Typography>
                                        </Grid>
                                        <Grid container gap={2} pt={3}>
                                            <AccessTimeIcon className="timeIcon"/>
                                            <Typography className="timeText">{formattedDuration}</Typography>
                                        </Grid>
                                        <Grid container gap={2} pt={3}>
                                            <CalendarTodayIcon className="timeIcon"/>
                                            <Typography className="timeText" sx={{width:'300px'}}>{sheduledData?.formattedDateTime}</Typography>
                                        </Grid>
                                        <Grid container gap={2} pt={3}>
                                            <PublicIcon className="timeIcon"/>
                                            <Typography className="timeText">India Standard Time</Typography>
                                        </Grid>
                                    </Grid>
                                    
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Grid>
        
        
            
        </>
    )
}