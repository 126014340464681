import React,{useState} from "react";
import { Grid, Typography,Popover, Button,Container,FormControl,TextareaAutosize,TextField,Link } from "@mui/material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PublicIcon from '@mui/icons-material/Public';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { ManagementServices } from "../services/managementServices";
import { SuccessAlert, ErrorAlert } from '../components/alert';
import { InnerHeaderComponent } from "../components/layout_components/innerHeaderComponent";
import { BackdropWithLottie } from "../components/lottie";

export const EventDetails = () => {
    const navigate =  useNavigate();
    const location = useLocation();
    const { eventSlot, startTime,endTime,onlyDate,timeSlotString ,eventId,formattedDuration} = location.state || {};
    const { control, handleSubmit, formState: { errors } } = useForm();
    const [alrtMsg, setAlrtMsg] = useState("");
    const [successAlrtOpen, setSuccessAlrtOpen] = useState(false);
    const [errorAlrtOpen, setErrorAlrtOpen] = useState(false);
    const [sheduledData,setSheduledData] = useState({});
    const user = JSON.parse(localStorage.getItem('user'));
    const dynamicLink = user ? `${window.location.origin}/${user.myLinkName}/${eventId}` : '';
    const [openLottie, setOpenLottie] = useState(false);

  
    const handleCloseLottie = () => {
        setOpenLottie(false);
    };
    const closeSuccess = () => {
        setSuccessAlrtOpen(false);
    };

    const closeError = () => {
        setErrorAlrtOpen(false);
    };
  
    const onSubmit = async (data) => {
        setOpenLottie(true);
        const payload = {
            attendeeName: data.name,
            attendeeEmail: data.email,
            notes:data.comments,
            start: startTime,
            end: endTime,
            date: onlyDate,
            eventId: eventSlot.id,
            attendeeTimeZone:"Asia/Kolkata",
            organizerEmail: eventSlot.email
        }
       try {
           const response = await ManagementServices.bookEventSlot(payload);
           if (response.status === 1) {
            setOpenLottie(false);
            setAlrtMsg(response.message);
            setSuccessAlrtOpen(true);
            setSheduledData(response.data);
            setTimeout(() => {
                navigate('/eventScheduled', { state: { sheduledData: response.data,eventId ,formattedDuration,eventSlot}});
            }, 500);
            } else {
                setOpenLottie(false);
                setAlrtMsg(response.message);
                setErrorAlrtOpen(true);
            }
        
       }
       catch (error) {
           console.error(error);
       }
    };
    return (
        <>
        <Grid sx={{height:'100vh'}}>
            {user && <InnerHeaderComponent
             linkToCopy={dynamicLink} 
             myLinkName={user?.myLinkName} 
             eventId={eventId}
             />}
            <Container className="containerAdjust"  sx={{ height: user ? '90%' : '100%' }} >
                <Grid py={5}  container alignItems={'center'} justifyContent={'center'} sx={{height:'100%'}}>
                    <Grid md={12}  className="createCard" p={2} sx={{height:'100%'}}>
                        <Grid container sx={{height:'100%'}}>
                            <Grid item md={4} p={2} className="cardBodyHalf">
                                <Grid className="backSpaceIcon" onClick={() => navigate(-1)} sx={{cursor:'pointer'}}>
                                    <KeyboardBackspaceIcon/>
                                </Grid>
                                {eventSlot?.profilePic&&<Grid className={'profileLetter profilePictureBlock'}>
                                    <img src={eventSlot?.profilePic} alt="eventImage" className="cardBodyImage" />
                                </Grid>}
                                <Typography className="cardBodyProfileName">{eventSlot?.userName}</Typography>
                                <Typography className="eventName">{eventSlot?.name}</Typography>
                                <Grid container gap={2} pt={3}>
                                    <AccessTimeIcon className="timeIcon"/>
                                    <Typography className="timeText">{formattedDuration}</Typography>
                                </Grid>
                                <Grid container gap={2} pt={3}>
                                    <CalendarTodayIcon className="timeIcon"/>
                                    <Typography className="timeText" sx={{width:'300px'}}>{timeSlotString}</Typography>
                                </Grid>
                                <Grid container gap={2} pt={3}>
                                    <PublicIcon className="timeIcon"/>
                                    <Typography className="timeText">India Standard Time</Typography>
                                </Grid>
                            </Grid>
                            <Grid item md={5} sx={{height:'100%'}} pt={3}>
                                <Grid sx={{height:'10%'}}>
                                    <Grid> 
                                        <Typography px={4} className="eventDetailsHeading">Enter Details</Typography>
                                    </Grid>
                                    <Link href={user ? `${window.location.origin}/app/events` : `${window.location.origin}`}>
                                        <Grid className="overflowBranding">
                                            <Grid className="brnadingBlock">
                                                <Typography>powered by</Typography>
                                                <Typography>Krifly</Typography>
                                            </Grid>
                                        </Grid>
                                    </Link>
                                </Grid>
                                <Grid>
                                <Grid item p={4}>
                                    <Grid component={'form'} onSubmit={handleSubmit(onSubmit)}>
                                        <Grid item>
                                            <FormControl fullWidth>
                                                <Grid className="labelName">Name</Grid>
                                                <Controller
                                                    name="name"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{ required: 'Name is required' }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            variant="outlined"
                                                            placeholder="Enter your name"
                                                            fullWidth
                                                            size="medium"
                                                            className="eventTextField"
                                                            error={!!errors.name}
                                                            helperText={errors.name ? errors.name.message : ''}
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                            <FormControl fullWidth className="formControl">
                                                <Grid className="labelName">Email</Grid>
                                                <Controller
                                                    name="email"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{ 
                                                        required: 'Email is required',
                                                        pattern: {
                                                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                                            message: 'Invalid email address'
                                                        }
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            variant="outlined"
                                                            placeholder="Enter your email"
                                                            fullWidth
                                                            size="medium"
                                                            className="eventTextField"
                                                            error={!!errors.email}
                                                            helperText={errors.email ? errors.email.message : ''}
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                            <FormControl fullWidth className="formControl">
                                                <Grid className="labelName">Please share anything that will help prepare for our meeting.</Grid>
                                                <Controller
                                                    name="comments"
                                                    control={control}
                                                    defaultValue=""
                                                    render={({ field }) => (
                                                        <TextareaAutosize
                                                            {...field}
                                                            aria-label="empty textarea"
                                                            placeholder="Write something..."
                                                            minRows={6}
                                                            style={{ width: '100%' }}
                                                            className="textArea"
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                            <Grid mt={3}>
                                                <Button type="submit" className="continueBtn">Schedule Event</Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                    
                                </Grid>
                                
                            
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Grid>

        <SuccessAlert alertMsg={alrtMsg} open={successAlrtOpen} close={closeSuccess} />
        <ErrorAlert alertMsg={alrtMsg} open={errorAlrtOpen} close={closeError} />
        <BackdropWithLottie open={openLottie} onClose={handleCloseLottie} />

        </>
    )
}