import React from 'react';
import { Box,Grid } from '@mui/material';
import { SideNav } from '../components/layout_components/sidenav';
import { HeaderComponent } from '../components/layout_components/headerComponent';
import { Outlet } from "react-router-dom";
import Footer from '../components/Footer';
export const Layout = () => {
    return (
        <>
            <Box>
                <Grid container>
                    <Grid item md={2}>
                        <SideNav />
                    </Grid>
                    <Grid item md={10} container justifyContent={'center'} className='layoutPage'>
                        <Grid item md={10} sx={{height:'100vh'}}>
                            <Grid sx={{height:'10vh'}}>
                                <HeaderComponent />
                            </Grid>
                            <Grid sx={{height:'90vh'}}>
                                <Outlet />
                                {/* <Footer/> */}
                            </Grid>
                        </Grid>
                        
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}