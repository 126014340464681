import React from 'react';
import { Button, Container, Grid,Link } from '@mui/material';
import Logo from '../../assets/logo.png';
import '../../styles/style.css';
import {  useNavigate,useLocation } from "react-router-dom";
export const Header = () => {
    const navigate =  useNavigate();
    const location = useLocation();

    const isLoginPage = location.pathname === '/login';
    const isSignUpPage = location.pathname === '/signup';
    return (
        <>
            <Grid className='headerBox'>
                <Container>
                    <Grid container justifyContent='space-between' alignItems={'center'}>
                        <Grid item onClick={() => {navigate('/');}}> 
                            <img src={Logo} alt= 'logo'/>
                        </Grid>
                        {!isLoginPage && !isSignUpPage && <Grid item>
                            <Grid container justifyContent={'space-between'} alignItems={'center'}>
                            <Grid item className='loginText'>
                                <Link underline="none" onClick={() => {navigate('/login');}}>Log in</Link>
                            </Grid>
                            <Button item className='startedBtn' onClick={() => {navigate('/signup');}}>Get Started</Button>
                            </Grid>
                        </Grid>}
                        {isSignUpPage &&
                        <Grid item className='loginText loginBtn'>
                            <Link underline="none" onClick={() => {navigate('/login');}}>Log in</Link>
                        </Grid>
                        }
                    </Grid>
                </Container>
            </Grid>
            
            
        </>
        
    );
}