import React from 'react';
import { Grid } from '@mui/material';

const CopyButton = ({ id, dynamicContent, onCopy, children, className, style }) => {
    const handleCopy = (event) => {
        event.stopPropagation();
        const linkToCopy = dynamicContent;

        console.log('Attempting to copy URL:', linkToCopy); 
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(linkToCopy)
                .then(() => {
                    if(onCopy){
                        onCopy(id);
                        setTimeout(() => {
                            onCopy(null);
                        }, 2000); 
                    }
                    
                })
                .catch((err) => {
                    console.error('Failed to copy with Clipboard API:', err);
                });
        } else {
            // Fallback method for copying
            const textArea = document.createElement('textarea');
            textArea.value = linkToCopy;
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                const successful = document.execCommand('copy');
                if (successful) {
                    if(onCopy){
                        onCopy(id);
                        setTimeout(() => {
                            onCopy(null);
                        }, 2000); 
                    }
                   
                } else {
                    console.error('Fallback: Oops, unable to copy');
                }
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err);
            } finally {
                document.body.removeChild(textArea);
            }
        }
    };

    return (
        <Grid
            onClick={handleCopy}
            className={className}
            style={style}
        >
            {children}
        </Grid>
    );
};

export default CopyButton;
