import React from 'react';
import { Popover, Grid, Typography } from '@mui/material';
export const CustomPopover = ({ id, open, anchorEl, handleClose, items }) => {
    return (
        <>
        <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                sx={{
                    marginTop: '20px',
                }}
                className='popoverEvent'
            >
                <Grid className="popover">
                   {items.map((item, index) => (
                    <Grid
                        key={index}
                        container
                        alignItems={'center'}
                        className={'popoverText'}
                        gap={1}
                        p={1.5}
                        onClick={item.onClick}
                    >
                        <item.icon />
                        <Typography className={'className="iconText'}>{item.text}</Typography>
                    </Grid>
                ))}
                </Grid>
            </Popover>
        </>
    )
}