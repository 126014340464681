import React from "react";
import Button from '@mui/material/Button';

export const SocialIconBtn = ({ icon: Icon, label, backgroundColor,borderColor,googleLogin }) => {
    return (
        <>
            <Button
                variant="contained"
                startIcon={<Icon />}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: backgroundColor,
                    color: 'white',
                    fontSize: '1rem',
                    borderRadius: '8px',
                    border: borderColor,
                    textTransform: 'capitalize',
                    fontWeight: '600',
                    padding: '10px 25px',
                    whiteSpace: 'nowrap',
                  }}
                onClick={googleLogin}
            >
                {label}
            </Button>

        </>
    )
}