import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Typography,
  Button,
  FormControl,
  TextField,
  TextareaAutosize,
  MenuItem,
  Select,
} from "@mui/material";
import Avatar from "../assets/avatar.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useForm, Controller } from "react-hook-form";
import { ManagementServices } from "../services/managementServices";
import { SuccessAlert, ErrorAlert } from "../components/alert";
import { useProfile } from "../components/contextProfile";
import { BackdropWithLottie } from "../components/lottie";

export const Profile = () => {
  const [profilePicture, setProfilePicture] = React.useState(null);
  const { setHeaderProfile } = useProfile();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const user = JSON.parse(localStorage.getItem("user"));
  const [alrtMsg, setAlrtMsg] = useState("");
  const [successAlrtOpen, setSuccessAlrtOpen] = useState(false);
  const [errorAlrtOpen, setErrorAlrtOpen] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const fileInputRef = useRef(null);
  const [openLottie, setOpenLottie] = useState(false);

  
    const handleCloseLottie = () => {
        setOpenLottie(false);
    };
  const closeSuccess = () => {
    setSuccessAlrtOpen(false);
  };

  const closeError = () => {
    setErrorAlrtOpen(false);
  };

  const handleProfilePictureUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setProfilePicture(file);
      const newProfilePicture = URL.createObjectURL(file);
      setHeaderProfile(newProfilePicture); // Store the file object
    } else {
      console.log("No file selected");
      setProfilePicture(null);
    }
  };
  

  const handleRemovePicture = () => {
    setProfilePicture(null);
    fileInputRef.current.value = "";
    setHeaderProfile(null);
  };

  const gettingProfileDetails = async () => {
    const payload = {
      userId: user.id,
    };
    try {
      const response = await ManagementServices.getProfile(payload);
      if (response.status === 1) {
        setUserDetails(response.data);
        if (response.data.decryptedProfilePic) {
          setProfilePicture(response.data.decryptedProfilePic);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  const onSubmit = async (data) => {
    setOpenLottie(true);
    const formData = new FormData();
    formData.append("userId", user.id);
    formData.append("name", data.name);
    formData.append("welcomeMessage", data.welcomeMessage);
    formData.append("dateFormat", data.date);
    formData.append("language", data.language);
    formData.append("timeFormat", data.time);
    formData.append("country", data.country);
    formData.append("timeZone", data.timeZone);
    if (profilePicture && typeof profilePicture !== "string") {
      formData.append("profilePic", profilePicture);
    }
    try {
      const response = await ManagementServices.updateProfile(formData);

      if (response.status === 1) {
        gettingProfileDetails();
        setOpenLottie(false);
        setSuccessAlrtOpen(true);
        setAlrtMsg(response.message);
        const updatedUser = { ...user, ...response.data };
        localStorage.setItem("user", JSON.stringify(updatedUser));
      } else {
        setOpenLottie(false);
        setErrorAlrtOpen(true);
        setAlrtMsg(response.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const imagePreview = typeof profilePicture === "string"
  ? profilePicture
  : profilePicture
  ? URL.createObjectURL(profilePicture)
  : Avatar;


  const handleCancel = () => {
    reset({
      name: userDetails.name || "",
      welcomeMessage: userDetails.welcomeMessage || "Welcome to my scheduling page. Please follow the instructions to add an event to my calendar.",
      date: userDetails.dateFormat || "DD/MM/YYYY",
      language: userDetails.language || "English",
      time: userDetails.timeFormat || "12h (am/pm)",
      country: userDetails.country || "India",
      timeZone: userDetails.timeZone || "India Standard Time",
    });
    if (userDetails.decryptedProfilePic) {
      setProfilePicture(userDetails.decryptedProfilePic);
      setHeaderProfile(userDetails.decryptedProfilePic);
    } else {
      setProfilePicture(null);
      setHeaderProfile(null);
    }
  };
  

  useEffect(() => {
    gettingProfileDetails();
  }, []);

  useEffect(() => {
    if (userDetails) {
      reset({
        name: userDetails.name || "",
        welcomeMessage:
          userDetails.welcomeMessage ||
          "Welcome to my scheduling page. Please follow the instructions to add an event to my calendar.",
        date: userDetails.dateFormat || "DD/MM/YYYY",
        language: userDetails.language || "English",
        time: userDetails.timeFormat || "12h (am/pm)",
        country: userDetails.country || "India",
        timeZone: userDetails.timeZone || "India Standard Time",
        profilePic: userDetails.decryptedProfilePic,
      });
      setHeaderProfile(userDetails?.decryptedProfilePic);
      localStorage.setItem("profilePic", userDetails?.decryptedProfilePic);
    }
  }, [userDetails]);
  return (
    <>
      <Grid container sx={{ height: "90vh", overflowY: "auto" }}>
        <Grid item md={8} py={5}>
          <Grid>
            <Typography className="timeText">Account details</Typography>
            <Grid component={"h1"} className="pageTitle">
              Profile
            </Grid>
          </Grid>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container alignItems={"end"}>
              <Grid item md={7}>
                <Grid container alignItems={"center"} gap={3}>
                  <Grid item className="profilePictureBlock">
                    <img src={imagePreview} alt="Profile" />
                  </Grid>
                  <Grid item>
                    {profilePicture ? (
                      <>
                        <Button
                          component="label"
                          className="btn createEventBtn"
                          sx={{ mr: 3 }}
                          onClick={() => fileInputRef.current.click()}
                        >
                          Update
                        </Button>
                        <Button
                          className="btn createEventBtn"
                          onClick={handleRemovePicture}
                        >
                          Remove
                        </Button>
                      </>
                    ) : (
                      <Button
                        component="label"
                        className="btn createEventBtn"
                        onClick={() => fileInputRef.current.click()}
                      >
                        Upload Picture
                      </Button>
                    )}
                    <input
                      ref={fileInputRef}
                      name="profilePic"
                      type="file"
                      accept="image/*"
                      hidden
                      onChange={handleProfilePictureUpload}
                    />
                  </Grid>
                </Grid>
                <Grid container flexDirection={"row"}>
                  <Grid item md={12} fullWidth>
                    <FormControl fullWidth className="formControl">
                      <Grid className="labelName">Name</Grid>
                      <Controller
                        name="name"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Name is required" }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            variant="outlined"
                            placeholder="Enter your name"
                            fullWidth
                            size="medium"
                            className="eventTextField"
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "& input": {
                                  background: ' #ffffff',
                                  height: '13px',
                                  borderRadius: '10px'
                                },
                               
                              },
                            }}
                            error={!!errors.name}
                            helperText={errors.name?.message}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={12} fullWidth className="formControl">
                    <FormControl fullWidth>
                      <Grid className="labelName">Welcome Message</Grid>
                      <Controller
                        name="welcomeMessage"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextareaAutosize
                            {...field}
                            aria-label="empty textarea"
                            placeholder="Write something..."
                            minRows={6}
                            style={{ width: "100%" }}
                            className="textArea"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={12}>
                    <FormControl fullWidth className="formControl">
                      <Grid className="labelName">Welcome Message</Grid>
                      <Controller
                        name="language"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <Select
                            {...field}
                            labelId="language-select"
                            id="language-select"
                            IconComponent={ExpandMoreIcon}
                            sx={{
                              borderRadius: "10px",
                              backgroundColor: "white",
                            }}
                            className="eventSelectField"
                          >
                            <MenuItem value="English">English</MenuItem>
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={12} fullWidth>
                    <Grid container gap={1}>
                      <Grid item md={5.8}>
                        <FormControl fullWidth className="formControl">
                          <Grid className="labelName">Date Formate</Grid>
                          <Controller
                            name="date"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <Select
                                {...field}
                                labelId="date-format-select"
                                id="date-format-select"
                                IconComponent={ExpandMoreIcon}
                                sx={{
                                  borderRadius: "10px",
                                  backgroundColor: "white",
                                }}
                                className="eventSelectField"
                              >
                                <MenuItem value="DD/MM/YYYY">
                                  DD/MM/YYYY
                                </MenuItem>
                                <MenuItem value="MM/DD/YYYY">
                                  MM/DD/YYYY
                                </MenuItem>
                              </Select>
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={5.8}>
                        <FormControl fullWidth className="formControl">
                          <Grid className="labelName">Time Formate</Grid>
                          <Controller
                            name="time"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <Select
                                {...field}
                                labelId="time-format-select"
                                id="time-format-select"
                                IconComponent={ExpandMoreIcon}
                                sx={{
                                  borderRadius: "10px",
                                  backgroundColor: "white",
                                }}
                                className="eventSelectField"
                              >
                                <MenuItem value="12h (am/pm)">
                                  12h (am/pm)
                                </MenuItem>
                                <MenuItem value="24h">24h</MenuItem>
                              </Select>
                            )}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={12} fullWidth>
                    <FormControl fullWidth className="formControl">
                      <Grid className="labelName">Country</Grid>
                      <Controller
                        name="country"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <Select
                            {...field}
                            labelId="country-select"
                            id="country-select"
                            IconComponent={ExpandMoreIcon}
                            sx={{
                              borderRadius: "10px",
                              backgroundColor: "white",
                            }}
                            className="eventSelectField"
                          >
                            <MenuItem value="India">India</MenuItem>
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={12} fullWidth>
                    <FormControl fullWidth className="formControl">
                      <Grid className="labelName">Time Formate</Grid>
                      <Controller
                        name="timeZone"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <Select
                            {...field}
                            labelId="time-zone-select"
                            id="time-zone-select"
                            IconComponent={ExpandMoreIcon}
                            sx={{
                              borderRadius: "10px",
                              backgroundColor: "white",
                            }}
                            className="eventSelectField"
                          >
                            <MenuItem value="India Standard Time">
                              <Grid container justifyContent={"space-between"}>
                                <Grid item>India Standard Time</Grid>
                              </Grid>
                            </MenuItem>
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={12} fullWidth pt={4}>
                    <Grid container alignItems={"center"} gap={4}>
                      <Grid item md={5}>
                        <Button type="submit" className="continueBtn">
                          Save Changes
                        </Button>
                      </Grid>
                      <Grid item md={5}>
                        <Button
                          className="btn createEventBtn"
                          sx={{ width: "100%" }}
                          onClick={handleCancel}
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* <Grid item md={6}>
                <Grid container justifyContent={"center"}>
                  <Button className="deleteBtn">Delete Account</Button>
                </Grid>
              </Grid> */}
            </Grid>
          </form>
        </Grid>
      </Grid>
      <SuccessAlert
        alertMsg={alrtMsg}
        open={successAlrtOpen}
        close={closeSuccess}
      />
      <ErrorAlert alertMsg={alrtMsg} open={errorAlrtOpen} close={closeError} />
      <BackdropWithLottie open={openLottie} onClose={handleCloseLottie} />

    </>
  );
};
