import { Snackbar } from "@mui/joy";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const vertical = "top";
const horizontal = "right";

export const SuccessAlert = ({ alertMsg, open, close }) => {
  return (
    <Snackbar
      autoHideDuration={3000}
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      variant={"soft"}
      className={"successAlert"}
      onClose={(event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        if (typeof close === 'function') {
          close();
        }
      }}
      startDecorator={<CheckCircleIcon />}
    >
      {alertMsg}
    </Snackbar>
  );
};

export const ErrorAlert = ({ alertMsg, open, close }) => {
  return (
    <Snackbar
      autoHideDuration={3000}
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      variant={"soft"}
      className={"errorAlert"}
      onClose={(event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        if (typeof close === 'function') {
          close();
        }
      }}
      startDecorator={<CancelIcon />}
    >
      {alertMsg}
    </Snackbar>
  );
};
